import React from 'react'
import { Carousel } from 'react-bootstrap'

export default function BootstrapCarouselturismo() {
    return (
        <div>
         

<h1 style={{color:'black'}}>Turismo en timbiqui!</h1>

<p style={{fontWeight:'bold',padding:'10px 0px 0px 0px', textAlign:'center'}}>En hoteles timbiqui te llevamos a los mejores y mas hermosos lugares de nuestra region!</p>
            

            <Carousel>
  <Carousel.Item>
    <img
      className="d-block w-100"
      style={{height: 600}}
      src="./img/slide_turismo1.png"
      alt="First slide"
    />
    <Carousel.Caption>
     
    
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      style={{height: 600}}
      src="./img/slide_turismo2.png"
      alt="Second slide"
    />


  </Carousel.Item>
</Carousel>
        </div>
    )
}

